import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../../../layout/dashboard/dashboardLayout';
import '../styles/index.css';
import {
	RavenInputField,
	RavenTableRow,
	RavenTable,
	RavenButton,
} from '@ravenpay/raven-bank-ui';
import { icons } from '../../../../../assets/icons';
import EmptyScreen from '../../../../../components/common/emptyScreen';
import {
	formatDateTime,
	formatNumWithComma,
	mapHotkey,
	symbol,
} from '../../../../../utils/helper/Helper';
import { faker } from '@faker-js/faker';
import Pagination from '../../../../../components/common/pagination';
import SmartFilter from '../../../../../components/common/smartFilter';
import PageLayout from '../../../../../layout/dashboard/tableLayout';
import { getSettlementHistory } from '../../../../../redux/wallet';
import { useDispatch, useSelector } from 'react-redux';
import RootState from '../../../../../redux/types';
import { logger } from '../../../../../services/logger';

// Generate an array to store the table data

const SettlementHistory = () => {
	const [currentPage, setCurrentPage] = useState(1);
	const [activeFilter, setActiveFilter] = useState<string>('');
	const [dateFilter, setDateFilter] = useState<{
		to: Date | any;
		from: Date | any;
	}>({
		from: '',
		to: '',
	});

	useEffect(() => {
		if (
			typeof dateFilter.from === 'object' ||
			typeof dateFilter.to === 'object'
		) {
			setDateFilter({
				to: '',
				from: '',
			});
		}
	}, [activeFilter]);

	useEffect(() => {
		if (typeof activeFilter !== undefined) {
			setActiveFilter('');
		}
	}, [dateFilter.to || dateFilter.from]);

	const dispatch = useDispatch();

	React.useEffect(() => {
		dispatch(
			getSettlementHistory({
				page: currentPage,
				perPage: 20,
			})
		);
	}, [currentPage]);

	const { settlementHistory, loading } = useSelector(
		(state: RootState) => state.wallet
	);

	const collx = settlementHistory || [];
	logger.log(settlementHistory, 'settlement  history');
	return (
		<DashboardLayout>
			<PageLayout
				topRightContent={<></>}
				pageSubtitle="Get an overview of all your settlement  history"
				pageTitle={'Settlement  History'}
			>
				{/* <SmartFilter /> */}

				<div className="content__main">
					<div
						className={`main__active-filters ${
							(activeFilter ||
								typeof dateFilter.to === 'object' ||
								typeof dateFilter.from === 'object') &&
							'main__active-filters--show'
						}`}
					>
						<span className="active-filters__item">
							<p>
								<b>Date:</b>
								{typeof dateFilter.to === 'object' ||
								typeof dateFilter.from === 'object'
									? formatDateTime(dateFilter.from).split(',')[0] +
									  ' - ' +
									  formatDateTime(dateFilter.to).split(',')[0]
									: activeFilter}
							</p>
							<figure>{icons.x_circle}</figure>
						</span>
						<span
							onClick={() => {
								setDateFilter({
									to: '',
									from: '',
								});
								setActiveFilter('');
							}}
							className="active-filters__clear-item"
						>
							<p>Clear All</p>
						</span>
					</div>
					<div className="content__main--top-bar">
						<div className="top-bar__title">
							{/* <h5>{activeCurrency.toUpperCase()} Settlement  History</h5> */}
						</div>
						<Pagination
							className="top-bar__pagination"
							currentPage={currentPage}
							itemsPerPage={20}
							totalItems={settlementHistory?.length}
							onPageChange={function (page: number): void {
								setCurrentPage(page);
							}}
						/>
					</div>

					<div className="main__wrap">
						{collx?.length === 0 || loading ? (
							<EmptyScreen
								subTitle="You currently don't have any settlement  history, your funding records will appear here."
								loading={loading}
								withMascot
							/>
						) : (
							<div className="transaction__table">
								<RavenTable
									action={false}
									className="table__main"
									headerList={[
										'REFERENCE',
										'AMOUNT',
										'BAL BEFORE',
										'BAL AFTER',
										'DATE SETTLED',
									]}
								>
									{collx.map((chi: any, idx: number) => {
										// const meta = chi.source ? JSON.parse(chi?.source) : {};

										return (
											<React.Fragment key={idx}>
												<RavenTableRow
													className="table__main"
													two={
														<div className="status-type-wrap ">
															<span>
																{symbol('ngn') +
																	formatNumWithComma(chi?.amount, 'ngn')}
															</span>
														</div>
													}
													five={formatDateTime(chi?.created_at)}
													onRowClick={function noRefCheck() {}}
													one={
														<div className="main__direction">
															{/* <figure>{icons[chi.direction]}</figure> */}
															<p className="text"> {chi?.reference}</p>
														</div>
													}
													three={
														<div className="type-wrap">
															<span>
																{symbol('ngn') +
																	formatNumWithComma(chi?.b_before, 'ngn')}
															</span>
														</div>
													}
													four={`${
														symbol('ngn') +
														formatNumWithComma(chi?.b_after, 'ngn')
													}`}
												/>
											</React.Fragment>
										);
									})}
								</RavenTable>
							</div>
						)}
					</div>
				</div>
			</PageLayout>
		</DashboardLayout>
	);
};

export default SettlementHistory;
