import { APIResponseHandler } from '../api';
import instance from '../axios';

interface RequestOptions {
	base_url?: string;
}

export async function sendRequest(
	url: string,
	data: any,
	options: RequestOptions = {}
) {
	const { base_url } = options;
	const requestUrl = base_url ? `${base_url}${url}` : url;

	try {
		const response = await instance.post(requestUrl, data);
		APIResponseHandler.tryHandler({ response });
		return response?.data ?? response;
	} catch (error) {
		APIResponseHandler.catchHandler(error);
		throw error;
	}
}
