import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../../../layout/dashboard/dashboardLayout';
import '../styles/index.css';
import {
	RavenInputField,
	RavenTableRow,
	RavenTable,
	RavenButton,
} from '@ravenpay/raven-bank-ui';
import { icons } from '../../../../../assets/icons';
import EmptyScreen from '../../../../../components/common/emptyScreen';
import {
	formatDateTime,
	mapHotkey,
	symbol,
} from '../../../../../utils/helper/Helper';
import { faker } from '@faker-js/faker';
import Pagination from '../../../../../components/common/pagination';
import { useDarkMode } from '../../../../../hooks/useDarkMode';

// Generate an array to store the table data

const BalanceHistory = () => {
	const [demo, setDemo] = useState(true);
	const [showFilter, setShowFilter] = useState<boolean>(false);
	const [activeCurrency, setActiveCurrency] = useState('usd');
	const [currentPage, setCurrentPage] = useState(1);
	const [activeFilter, setActiveFilter] = useState<string>('');
	const [dateFilter, setDateFilter] = useState<{
		to: Date | any;
		from: Date | any;
	}>({
		from: '',
		to: '',
	});

	const tableData: {
		narration: string;
		type: string;
		direction: string;
		amount: string;
		balance: string;
		date: Date;
	}[] = [];

	// Generate 10 objects with fake data for the table rows
	for (let i = 0; i < 10; i++) {
		const type = faker.helpers.arrayElement(['Deposit', 'Transfer']);
		const rowData = {
			narration: faker.helpers.arrayElement([
				'Initial Deposit',
				'Money I Borrowed',
				'Transfer Out',
				'Transfer In',
			]),
			type: type,
			direction: type === 'Deposit' ? 'credit' : 'debit',
			amount: faker.finance.amount(),
			balance: faker.finance.amount(),
			date: faker.date.past(),
		};

		tableData.push(rowData);
	}

	const [theme, toggleTheme] = useDarkMode(localStorage.getItem('theme'));

	function currency(cur: string) {
		if (cur === activeCurrency) return true;
		else return false;
	}

	function isFilter(value: string) {
		if (value === activeFilter) return true;
		else return false;
	}

	useEffect(() => {
		mapHotkey('m', () => setDemo(!demo));
	}, [demo]);

	useEffect(() => {
		if (
			typeof dateFilter.from === 'object' ||
			typeof dateFilter.to === 'object'
		) {
			setDateFilter({
				to: '',
				from: '',
			});
		}
	}, [activeFilter]);

	useEffect(() => {
		if (typeof activeFilter !== undefined) {
			setActiveFilter('');
		}
	}, [dateFilter.to || dateFilter.from]);

	return (
		<DashboardLayout>
			<div className={`history-wrap ${theme}`}>
				<div className="history-wrap__top">
					<div className="top__title ">
						<h5>Balance History</h5>
						<p>Welcome back to your dashboard. here’s an overview</p>
					</div>
					<div className="top__wallet-select">
						<span
							onClick={() => setActiveCurrency('usd')}
							className={`wallet-select__item ${currency('usd') && 'active'} `}
						>
							USD
						</span>
						<span
							onClick={() => setActiveCurrency('ngn')}
							className={`wallet-select__item ${currency('ngn') && 'active'} `}
						>
							NGN
						</span>
						<span
							onClick={() => setActiveCurrency('gbp')}
							className={`wallet-select__item ${currency('gbp') && 'active'} `}
						>
							GBP
						</span>
						<span
							onClick={() => setActiveCurrency('eur')}
							className={`wallet-select__item ${currency('eur') && 'active'} `}
						>
							EUR
						</span>
						<span
							onClick={() => setActiveCurrency('ksh')}
							className={`wallet-select__item ${currency('ksh') && 'active'} `}
						>
							KSH
						</span>
						<span
							onClick={() => setActiveCurrency('rwf')}
							className={`wallet-select__item ${currency('rwf') && 'active'} `}
						>
							RWF
						</span>
					</div>
				</div>

				<div className="history__content">
					<div className="content__top-bar">
						<div className="top-bar__search">
							<RavenInputField
								placeholder="Search"
								type="search"
								color="deep-green-light"
							/>
						</div>
						<div className="top-bar__filter-export">
							<div
								onClick={() => setShowFilter(!showFilter)}
								className="filter-export__filter-btn"
							>
								<p>Filter</p> <figure>{icons.filter}</figure>
							</div>

							{/* Section encompassing filter and it controls */}
							<div
								className={`filter-export__drop-down ${
									showFilter && 'filter-export__drop-down--open'
								}`}
							>
								<div className="drop-down__switch">
									<span
										onClick={() => setActiveFilter('All')}
										className={`${isFilter('All') && 'active'}`}
									>
										<p>All</p>
									</span>
									<span
										onClick={() => setActiveFilter('Today')}
										className={`${isFilter('Today') && 'active'}`}
									>
										<p>Today</p>
									</span>
									<span
										onClick={() => setActiveFilter('Last 7 Days')}
										className={`${isFilter('Last 7 Days') && 'active'}`}
									>
										<p>Last 7 Days</p>
									</span>
									<span
										onClick={() => setActiveFilter('Last 30 Days')}
										className={`${isFilter('Last 30 Days') && 'active'}`}
									>
										<p>Last 30 Days</p>
									</span>
								</div>
								<div className="drop-down__custom-date-range">
									<h5>Custom Date Range</h5>
									<div className="custom-date-range__grouped-form">
										<RavenInputField
											type="date"
											color="deep-green-light"
											label="From"
											value={dateFilter.from}
											onChange={(e: Date) => {
												setDateFilter({
													...dateFilter,
													from: e,
												});
											}}
											placeholder="DD MM YYYY"
										/>
										<RavenInputField
											type="date"
											color="deep-green-light"
											label="To"
											value={dateFilter.to}
											onChange={(e: Date) => {
												setDateFilter({
													...dateFilter,
													to: e,
												});
											}}
											placeholder="DD MM YYYY"
										/>
									</div>
									<div className="drop-down__action-btns">
										<RavenButton
											onClick={() => {
												setDateFilter({
													to: '',
													from: '',
												});
												setActiveFilter('');
											}}
											color="green-light"
											label="Clear Filter"
										/>
										<RavenButton
											color="deep-green-light"
											label="Apply Filter"
											onClick={() => setShowFilter(false)}
										/>
									</div>
								</div>
							</div>
							<div
								onClick={() => setShowFilter(false)}
								className={`filter-export__drop-down-closer ${
									showFilter && 'filter-export__drop-down-closer--opened'
								}`}
							></div>
							{/* End of Section encompassing filter and it controls */}

							<div className="filter-export__export-btn">
								<p>Export</p> <figure>{icons.export}</figure>
							</div>
						</div>
					</div>
					<div className="content__main">
						<div
							className={`main__active-filters ${
								(activeFilter ||
									typeof dateFilter.to === 'object' ||
									typeof dateFilter.from === 'object') &&
								'main__active-filters--show'
							}`}
						>
							<span className="active-filters__item">
								<p>
									<b>Date:</b>
									{typeof dateFilter.to === 'object' ||
									typeof dateFilter.from === 'object'
										? formatDateTime(dateFilter.from).split(',')[0] +
										  ' - ' +
										  formatDateTime(dateFilter.to).split(',')[0]
										: activeFilter}
								</p>
								<figure>{icons.x_circle}</figure>
							</span>
							<span
								onClick={() => {
									setDateFilter({
										to: '',
										from: '',
									});
									setActiveFilter('');
								}}
								className="active-filters__clear-item"
							>
								<p>Clear All</p>
							</span>
						</div>
						<div className="content__main--top-bar">
							<div className="top-bar__title">
								<h5>{activeCurrency.toUpperCase()} Balance History</h5>
								<p className={`${demo && 'active'}`}>{`${faker.number.bigInt({
									min: '50',
									max: '700',
								})}`}</p>
							</div>
							<Pagination
								className="top-bar__pagination"
								currentPage={currentPage}
								itemsPerPage={10}
								totalItems={100}
								onPageChange={function (page: number): void {
									setCurrentPage(page);
								}}
							/>
						</div>

						<div className="main__wrap">
							{demo ? (
								<EmptyScreen
									subTitle="Your USD balance history will show up here, start transacting in USD to see your USD history."
									withMascot
								/>
							) : (
								<div className="transaction__table">
									<RavenTable
										action={false}
										className="table__main"
										headerList={[
											'NARRATION',
											'TYPE',
											'AMOUNT',
											'BALANCE',
											'TRANSACTION DATE',
										]}
									>
										{tableData.map((chi, idx) => {
											return (
												<React.Fragment key={idx}>
													<RavenTableRow
														className="table__main"
														four={
															<div className="status-type-wrap ">
																<span>{`${symbol(activeCurrency)}${
																	chi.amount
																}`}</span>
															</div>
														}
														five={formatDateTime(chi.date)}
														onRowClick={function noRefCheck() {}}
														one={
															<div className="main__direction">
																<figure>{icons[chi.direction as 'selected']}</figure>
																<p className="text"> {chi.narration}</p>
															</div>
														}
														three={
															<div className="type-wrap">
																<span>
																	{symbol(activeCurrency) + chi.balance}
																</span>
															</div>
														}
														two={`${chi.type}`}
													/>
												</React.Fragment>
											);
										})}
									</RavenTable>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</DashboardLayout>
	);
};

export default BalanceHistory;
