import React, { useEffect, useState } from 'react';
import '../styles/index.css';
import { icons } from '../../../../../assets/icons';

import { RavenInputProps } from '../../../../../../node_modules/@ravenpay/raven-bank-ui/dist/cjs/components/types.d';
import { RavenButton, RavenInputField } from '@ravenpay/raven-bank-ui';
import Copy from '../../../../../components/common/copyCheck';
import { useNavigate } from 'react-router-dom';
import { updateWebhook } from '../../../../../redux/settings';
import { useDispatch } from 'react-redux';


const SettingsWebhooksKeys = ({ keys }: any) => {
	const [revealed, setRevealed] = useState(false);
	const [readOnly, setReadOnly] = useState(0);
	const [isEditing, setIsEditing] = useState(0);
	const [isLoading, setIsLoading] = useState(0);
	const [updateData, setUpdateData] = useState({
		webhook_secret_key: '',
		webhook_url: '',
	});

	const dispatch = useDispatch();
	function formatSecrets(secret: string) {
		return secret?.length > 45
			? (secret?.slice(0, 45) as string) + '...'
			: (secret as string);
	}

	async function handleUpdate(num = 0) {
		setIsLoading(num);
		const resp = await dispatch(updateWebhook(updateData));
		console.log(updateData);

		if (resp.payload?.status === 'success') {
			setIsEditing(0);
			setUpdateData({
				webhook_secret_key: '',
				webhook_url: '',
			});
			setRevealed(false);
			setReadOnly(0);
			setIsLoading(0);
		} else {
			setIsLoading(0);
		}
	}

	function onEdit(num = 0) {
		if (readOnly === num) {
			setReadOnly(0);
			setRevealed(false);
		} else {
			setReadOnly(num);
			setRevealed(true);
		}
	}

	function isValidHttpUrl(string: string) {
		let url;

		try {
			url = new URL(string);
		} catch (_) {
			return false;
		}

		return (
			url.protocol === 'http:' ||
			url.protocol === 'https:' ||
			url.protocol === 'www'
		);
	}

	useEffect(() => {
		setUpdateData({
			...updateData,
			webhook_secret_key: keys?.webhook_secret_key,
			webhook_url: keys?.webhook_url,
		});
	}, [keys]);
	console.log(readOnly);

	return (
		<div className="webhook-settings">
			<div className="webhook-settings__title">
				<h5>Keys and Webhooks</h5>
				<p>Manage all your API Keys in one place.</p>
			</div>

			<div className="webhook-settings__main">
				<div className="main__left">
					<div className="left__input">
						<RavenInputField
							label={'Live Public'}
							type="password"
							readOnly
							color="deep-green-light"
							placeholder={
								revealed
									? `${formatSecrets(keys?.test_public_key)}`
									: `******************`
							}
						/>
						<Copy
							icon={icons.copy_black}
							className="input__copy-icon"
							text={keys?.test_public_key}
						/>
					</div>
					<div className="left__input">
						<RavenInputField
							label={'Test Secret'}
							type="password"
							color="deep-green-light"
							readOnly
							placeholder={
								revealed
									? `${formatSecrets(keys?.test_secret_key)}`
									: `******************`
							}
						/>
						<Copy
							icon={icons.copy_black}
							className="input__copy-icon"
							text={keys?.test_secret_key}
						/>
					</div>
					<div className="left__input">
						<RavenInputField
							label={
								<div className="settings__webhook">
									<p>Webhook URL</p>
									<figure
										onClick={() => {
											isEditing != 0 ? handleUpdate(1) : onEdit(1);
										}}
									>
										<p>
											{isLoading === 1
												? 'Saving...'
												: isEditing === 1
												? 'Save'
												: 'Edit'}
										</p>{' '}
										{isLoading === 1
											? icons.save
											: isEditing === 1
											? icons.save
											: icons.pen}
									</figure>
								</div>
							}
							type={revealed ? 'text' : 'password'}
							readOnly={readOnly !== 1 ? true : false}
							onChange={(e: any) => {
								setUpdateData({
									...updateData,
									webhook_url: e.target.value,
								});
								setIsEditing(1);
							}}
							showError={
								updateData.webhook_url?.length > 3 &&
								!isValidHttpUrl(updateData.webhook_url)
							}
							errorText="A valid url must contain https or http"
							color="deep-green-light"
							placeholder={
								revealed
									? `${formatSecrets(keys?.webhook_url || '--')}`
									: `******************`
							}
						/>
						<Copy
							icon={icons.copy_black}
							className="input__copy-icon"
							text={keys?.webhook_url || '--'}
						/>
					</div>
				</div>
				<div className="main__right">
					<div className="left__input">
						<RavenInputField
							label={'Live Public Key'}
							type="password"
							readOnly
							color="deep-green-light"
							placeholder={
								revealed
									? `${formatSecrets(keys?.live_public_key)}`
									: `******************`
							}
						/>
						<Copy
							icon={icons.copy_black}
							className="input__copy-icon"
							text={keys?.live_public_key}
						/>
					</div>
					<div className="left__input">
						<RavenInputField
							label={'Live Secret Key'}
							type="password"
							color="deep-green-light"
							readOnly
							placeholder={
								revealed
									? `${formatSecrets(keys?.live_secret_key)}`
									: `******************`
							}
						/>
						<Copy
							icon={icons.copy_black}
							className="input__copy-icon"
							text={keys?.live_secret_key}
						/>
					</div>
					<div className="left__input">
						<RavenInputField
							label={
								<div className="settings__webhook">
									<p>Webhook Secret</p>
									<figure
										onClick={() => {
											isEditing === 2 ? handleUpdate(2) : onEdit(2);
										}}
									>
										<p>
											{isLoading === 2
												? 'Saving'
												: isEditing === 2
												? 'Save'
												: 'Edit'}
										</p>{' '}
										{isLoading === 2
											? icons.save
											: isEditing === 2
											? icons.save
											: icons.pen}
									</figure>
								</div>
							}
							type={revealed ? 'text' : 'password'}
							readOnly={readOnly !== 2 ? true : false}
							onChange={(e: any) => {
								setUpdateData({
									...updateData,
									webhook_secret_key: e.target.value,
								});
								setIsEditing(2);
							}}
							color="deep-green-light"
							placeholder={
								revealed
									? keys?.webhook_secret_key || '--'
									: `******************`
							}
						/>

						<Copy
							icon={icons.copy_black}
							className="input__copy-icon"
							text={keys?.webhook_secret_key || '--'}
						/>
					</div>
				</div>
			</div>
			<div className="webhook-settings__button">
				<RavenButton
					label={revealed ? 'Hide Secrets' : 'Reveal Secrets'}
					onClick={() => setRevealed(!revealed)}
					color="deep-green-light"
				/>
			</div>
		</div>
	);
};

export default SettingsWebhooksKeys;
